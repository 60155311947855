import React, { useState, useEffect } from "react";
import moment from "moment";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import TranslationMessage from "configs/translations/TranslationMessage";
import Topbar from "components/topbar/Topbar";
import * as S from "./registration.styles";
import * as C from "components/formComponents/checkbox/checkbox.styles";
import * as Data from "./registration.utils";

// Redux
import * as AuthActions from "redux/auth/auth.actions";

// Images
import Male from "assets/images/male.svg";
import Female from "assets/images/female.svg";
import Slimming from "assets/images/slimming.svg";
import HealthDiet from "assets/images/healthDiet.svg";
import MuscleBuilding from "assets/images/muscleBuilding.svg";
import MuscleBuildingBulk from "assets/images/muscleBuildingBulk.svg";
import Logo from "assets/images/Foodiary-Logo.PNG";

import topbarLogo from "assets/images/logo.svg";
import Apple from "assets/images/Icon-Apple.svg";
import Email from "assets/images/Icon-EMail.svg";
import Google from "assets/images/Icon-Google.svg";
import LeftIcon from "assets/images/leftIcon.svg";

// Components
import Input from "components/formComponents/input/Input";
import IncrementInput from "components/formComponents/incrementInput/IncrementInput";
import CollapsibleAccordion from "components/collapsibleAccordion/CollapsibleAccordion";
import Button from "components/formComponents/button/Button";
import Modal from "components/modal/Modal";
import Dropdown from "components/formComponents/dropdown/Dropdown";
import Switch from "components/formComponents/switch/Switch";
import Checkbox from "components/formComponents/checkbox/Checkbox";
import RadioButton from "components/formComponents/radioButton/RadioButton";
import SquareCheckbox from "components/formComponents/checkbox/SquareCheckbox";
import TimePicker from "components/timePicker/TimePicker";
import ErrorMessage from "components/formComponents/errorMessage/ErrorMessage";
import ProgressBar from "components/progressBar/ProgressBar";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import AppleLogin from "react-apple-login";

const Registration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const registrationData = useSelector((state) => state.auth.registrationData);
  const trainerID = useSelector(
    (state) => state.coach.trainer.trainerID ?? null
  );
  const error = useSelector((state) => state?.auth?.error);
  const { id } = useParams();
  const [isFirstTime, setIsFirstTime] = useState(true);

  const [addDailyRoutineModal, setAddDailyRoutineModal] = useState(false);
  const [training, setTraining] = useState(registrationData.isTraining);
  const [routineName, setRoutineName] = useState("");
  const [termsValue, setTermsValue] = useState(false);
  const [dataProtectionValue, setDataProtectionValue] = useState(false);
  const [showError, setShowError] = useState(false);
  const [routineGetup, setRoutineGetup] = useState({
    dayroutineID: 1,
    time: "07:00",
  });
  const [routineLunchTime, setRoutineLunchTime] = useState({
    dayroutineID: 2,
    time: "12:00",
  });
  const [routineWorkoutTime, setRoutineWorkoutTime] = useState({
    dayroutineID: 3,
    time: "17:00",
  });
  const [routineBedTime, setRoutineBedTime] = useState({
    dayroutineID: 4,
    time: "22:00",
  });
  const [isMobile, setIsMobile] = useState(false);
  const [gender, setGender] = useState(registrationData.sex);
  const [goal, setGoal] = useState(registrationData.aimID);
  const [birthDate, setBirthDate] = useState(registrationData.birthday);
  const [height, setHeight] = useState(registrationData.height);
  const [weight, setWeight] = useState(registrationData.weight);
  const [email, setEmail] = useState(registrationData.email);
  const [firstName, setFirstName] = useState(registrationData.prename);
  const [lastName, setLastName] = useState(registrationData.surname);
  const [mobile, setMobile] = useState(registrationData.mobile);
  const [password, setPassword] = useState(registrationData.password);
  const [trainingDays, setTrainingDays] = useState(
    registrationData.trainingdays
  );
  const [totalTrainingDays, setTotalTrainingDays] = useState(
    registrationData.totalTrainingDays
  );
  const [dailyRoutineData, setDailyRoutineData] = useState(
    registrationData.dailyRoutineData
  );
  const [routines, setRoutines] = useState(registrationData.routines);

  const [sleepValue, setSleepValue] = useState(registrationData.sleepValue);
  const [lyingDownValue, setLyingDownValue] = useState(
    registrationData.lyingDownValue
  );
  const [standingValue, setStandingValue] = useState(
    registrationData.standingValue
  );
  const [sittingValue, setSittingValue] = useState(
    registrationData.sittingValue
  );
  const [walkingValue, setWalkingValue] = useState(
    registrationData.walkingValue
  );
  const [exercisingValue, setExercisingValue] = useState(
    registrationData.exercisingValue
  );

  const [eatingHabit1, setEatingHabit1] = useState(
    registrationData.eatingHabit1
  );
  const [eatingHabit2, setEatingHabit2] = useState(
    registrationData.eatingHabit2
  );
  const [eatingHabit3, setEatingHabit3] = useState(
    registrationData.eatingHabit3
  );
  const [eatingHabit4, setEatingHabit4] = useState(
    registrationData.eatingHabit4
  );
  const [eatingHabit5, setEatingHabit5] = useState(
    registrationData.eatingHabit5
  );

  const totalMomentHrs =
    (sleepValue +
      lyingDownValue +
      standingValue +
      sittingValue +
      walkingValue +
      exercisingValue) /
    60;

  const [prevLink, setPrevLink] = useState("/start");
  const [nextLink, setNextLink] = useState("/registration/goal");

  const buttonData = [
    {
      id: "button1",
      image: Email,
      heading: "registration.email.heading",
      color: "#F8A492",
    },
    {
      id: "button2",
      image: Google,
      heading: "registration.google.heading",
      color: "#4285F4",
    },
    {
      id: "button3",
      image: Apple,
      heading: "registration.apple.heading",
      color: "#000000",
    },
  ];

  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const clientId =
    "871985774994-26cj0o9tefe4sb38bpk4h1du6h15lv3u.apps.googleusercontent.com"; //'706005637370-bisnrpfi1in2p3ud7t419mpjm7ctvfbg.apps.googleusercontent.com';
  const appleClientId =
    "MIGTAgEAMBMGByqGSM49AgEGCCqGSM49AwEHBHkwdwIBAQQgRAoBrVQk248Sclktm0KC6Sto/yPLVvK/K07STFtlfuOgCgYIKoZIzj0DAQehRANCAAS7fyPvjfi4bd6t3YYgrhoggqDRucaDghHTn/rwUUUIBuvA9OGru31+2a6+Dg5J/5kfnbg/EsJHZPr51fRA8mf0"; //"5C5Q78FTVF.com.foodiary.nutrition";
  useEffect(() => {
    document.body.classList.add("app-register");
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
    handleResize();
    handleLinksPosition();
    return () => {
      document.body.classList.remove("app-register");
    };
  }, []);

  useEffect(() => {
    if (termsValue && dataProtectionValue) {
      setShowError(false);
    }
  }, [termsValue, dataProtectionValue]);

  const onSuccess = (res) => {
    if (res.accessToken) {
      localStorage.setItem("token", res.accessToken);
      history.push("/payment");
    }
  };
  const onFailure = (err) => {
    console.log("failed:", err);
  };

  const handleLinksPosition = (nextLink) => {
    let newCurrentLinkPosition = Data.RegistrationLinks.indexOf(
      `/registration/${id}`
    );
    if (nextLink) {
      newCurrentLinkPosition = Data.RegistrationLinks.indexOf(nextLink);
    }
    let newPrevLink = Data.RegistrationLinks[newCurrentLinkPosition - 1];
    let newNextLink = Data.RegistrationLinks[newCurrentLinkPosition + 1];
    setPrevLink(newPrevLink);
    setNextLink(newNextLink);
  };

  const handlePrev = () => {
    handleLinksPosition(prevLink);
    history.push(prevLink);
  };
  const handleNext = () => {
    if (updateRegistrationData) {
      updateRegistrationData();
      if (handleNextRedirect()) {
        history.push(nextLink);
        if (Data.RegistrationLinks) handleLinksPosition(nextLink);
      }
    } else if (Data.RegistrationLinks) handleLinksPosition(nextLink);
    else history.push(nextLink);
  };

  console.log("sleep value", sleepValue);
  const activityLevelValues = [
    {
      id: "registration.activityLevel.sleep",
      options: Data.hourValues,
      value: sleepValue / 60 + " h",
    },
    {
      id: "registration.activityLevel.lyingDown",
      options: Data.hourValues,
      value: lyingDownValue / 60 + " h",
    },
    {
      id: "registration.activityLevel.standing",
      options: Data.hourValues,
      value: standingValue / 60 + " h",
    },
    {
      id: "registration.activityLevel.siting",
      options: Data.hourValues,
      value: sittingValue / 60 + " h",
    },
    {
      id: "registration.activityLevel.walking",
      options: Data.hourValues,
      value: walkingValue / 60 + " h",
    },
    {
      id: "registration.activityLevel.exercising",
      options: Data.hourValues,
      value: exercisingValue / 60 + " h",
    },
  ];

  const formData = {
    trainerID: trainerID,
    sex: gender,
    aimID: goal,
    height: height,
    weight: weight,
    nutritiontypeID: 2,
    birthday: birthDate,
    email: email,
    prename: firstName,
    surname: lastName,
    mobile: mobile,
    grant_type: "password",
    //isTraining: true,
    trainingdays: trainingDays,
    totalTrainingDays: totalTrainingDays,
    dailyRoutineData: dailyRoutineData,
    routines: routines,
    movementtypes: [
      { minutes: sleepValue, movementtypeID: 1 },
      { minutes: lyingDownValue, movementtypeID: 2 },
      { minutes: standingValue, movementtypeID: 3 },
      { minutes: sittingValue, movementtypeID: 4 },
      { minutes: walkingValue, movementtypeID: 5 },
      { minutes: exercisingValue, movementtypeID: 6 },
    ],
    sleepValue: sleepValue,
    lyingDownValue: lyingDownValue,
    standingValue: standingValue,
    sittingValue: sittingValue,
    walkingValue: walkingValue,
    exercisingValue: exercisingValue,
    eatingHabit1: eatingHabit1,
    eatingHabit2: eatingHabit2,
    eatingHabit3: eatingHabit3,
    eatingHabit4: eatingHabit4,
    eatingHabit5: eatingHabit5,
  };

  const registrationSuccessRedirection = () => history.push("/payment");

  const updateRegistrationData = () => {
    if (id === "bodyDetails") setIsFirstTime(false);
    dispatch(AuthActions.setRegistrationData(formData));
    if (id === "personalData") {
      const values = formData;
      console.log("values", values.email);
      localStorage.setItem("userEmail", values.email);
      values.type = "mobile";
      values.password = registrationData.password
        ? registrationData.password
        : "12345678";
      if (!training) values.trainingdays = totalTrainingDays;
      delete values.totalTrainingDays;
      delete values.dailyRoutineData;
      delete values.sleepValue;
      delete values.lyingDownValue;
      delete values.standingValue;
      delete values.sittingValue;
      delete values.walkingValue;
      delete values.exercisingValue;
      delete values.eatingHabit1;
      delete values.eatingHabit2;
      dispatch(
        AuthActions.registration1Request({
          formValues: values,
          func: registrationSuccessRedirection,
        })
      );
    }
  };

  window.onhashchange = function () {
    console.log("called");
  };

  const handleNextRedirect = () => {
    if (id === "bodyDetails")
      if (parseInt(weight) < 40 || parseInt(height) < 120 || birthDate === "")
        return false;
    if (id === "dailyRoutine") if (dailyRoutineData.length === 0) return false;
    if (id === "activityLevel") if (totalMomentHrs !== 24) return false;
    if (id === "trainingFixed") {
      if (training) if (trainingDays.length === 0) return false;
      if (!training) if (parseInt(totalTrainingDays) === 0) return false;
    }
    if (id === "personalData") return false;
    return true;
  };

  const handleAddRoutine = () => {
    const uniqueId = "id" + new Date().getTime();
    const newRoutine = {
      id: uniqueId,
      heading: routineName,
      data: [
        routineGetup["time"],
        routineLunchTime["time"],
        routineWorkoutTime["time"],
        routineBedTime["time"],
      ],
    };
    setDailyRoutineData([...dailyRoutineData, newRoutine]);
    setRoutines({
      ...routines,
      [newRoutine.heading]: [
        { dayroutineID: 1, time: newRoutine.data[0] },
        { dayroutineID: 2, time: newRoutine.data[1] },
        { dayroutineID: 3, time: newRoutine.data[2] },
        { dayroutineID: 4, time: newRoutine.data[3] },
      ],
    });
    setRoutineName("");
    setRoutineGetup({ dayroutineID: 1, time: "07:00" });
    setRoutineLunchTime({ dayroutineID: 2, time: "12:00" });
    setRoutineWorkoutTime({ dayroutineID: 3, time: "17:00" });
    setRoutineBedTime({ dayroutineID: 4, time: "22:00" });
    setAddDailyRoutineModal(false);
  };

  const handleTimeChange = (time, TimeId) => {
    const timeValue = moment(time).format("hh:mm");
    switch (TimeId) {
      case "input.label.getup":
        setRoutineGetup({ dayroutineID: 1, time: timeValue });
        break;
      case "input.label.lunchTime":
        setRoutineLunchTime({ dayroutineID: 2, time: timeValue });
        break;
      case "input.label.workoutTime":
        setRoutineWorkoutTime({ dayroutineID: 3, time: timeValue });
        break;
      case "input.label.bedTime":
        setRoutineBedTime({ dayroutineID: 4, time: timeValue });
        break;
      default:
        return dailyRoutineData;
    }
  };

  const handleActivityLevelValueChange = (e, activityLevelId) => {
    const totalMinutes = e.target.value.split(" ")[0] * 60;
    switch (activityLevelId) {
      case "registration.activityLevel.sleep":
        setSleepValue(totalMinutes);
        break;
      case "registration.activityLevel.lyingDown":
        setLyingDownValue(totalMinutes);
        break;
      case "registration.activityLevel.standing":
        setStandingValue(totalMinutes);
        break;
      case "registration.activityLevel.siting":
        setSittingValue(totalMinutes);
        break;
      case "registration.activityLevel.walking":
        setWalkingValue(totalMinutes);
        break;
      case "registration.activityLevel.exercising":
        setExercisingValue(totalMinutes);
        break;
      default:
        return totalMinutes;
    }
  };

  const handleTrainingFixedCheckbox = (checked, value) => {
    let arrayValues = trainingDays.slice();
    if (checked) {
      arrayValues.push(value);
      setTrainingDays(() => arrayValues);
    } else {
      const newArrayValue = arrayValues.filter(
        (dayValue) => dayValue !== value
      );
      setTrainingDays(newArrayValue);
    }
  };

  const handleRadioChange = (e, radioName) => {
    if (radioName === "eatingHabit1") setEatingHabit1(e.target.id);
    if (radioName === "eatingHabit2") setEatingHabit2(e.target.id);
    if (radioName === "eatingHabit3") setEatingHabit3(e.target.id);
    if (radioName === "eatingHabit4") setEatingHabit4(e.target.id);
    if (radioName === "eatingHabit5") setEatingHabit5(e.target.id);
  };

  const eatingHabits = [
    { id: "eatingHabit1", data: Data.eatingHabits1Data, state: eatingHabit1 },
    { id: "eatingHabit2", data: Data.eatingHabits2Data, state: eatingHabit2 },
    { id: "eatingHabit3", data: Data.eatingHabits3Data, state: eatingHabit3 },
    { id: "eatingHabit4", data: Data.eatingHabits4Data, state: eatingHabit4 },
    { id: "eatingHabit5", data: Data.eatingHabits5Data, state: eatingHabit5 },
  ];

  const EatingHabitsContent = (allData, radioName, state) => {
    return (
      <>
        {allData.map((data) => {
          return (
            <S.EatingHabitsCardContainer key={data.id}>
              <S.EatingHabitsImageContainer src={data.image} alt={data.id} />
              <S.EatingHabitsCardInnerContainer>
                <S.EatingHabitsInnerCardHeading>
                  <TranslationMessage id={data.heading} />
                </S.EatingHabitsInnerCardHeading>
                <S.EatingHabitsRadioContainer>
                  <RadioButton
                    checked={data.heading === state}
                    name={radioName}
                    id={data.heading}
                    onClick={(e) => handleRadioChange(e, radioName)}
                  />
                </S.EatingHabitsRadioContainer>
              </S.EatingHabitsCardInnerContainer>
            </S.EatingHabitsCardContainer>
          );
        })}
      </>
    );
  };

  const changeTermsValue = () => {
    setTermsValue(true);
  };

  const AccordionContent = () => {
    let AccordionData = [];
    let spanStyle = { display: "flex", float: "right", color: "#A5B5AF" };
    dailyRoutineData.map((data) => {
      const contentContainer = () => {
        return (
          <div key={data.id}>
            {Data.AccordionContentData.map((label, index) => {
              return (
                <div style={{ marginBottom: "10px" }} key={label.id}>
                  <TranslationMessage id={label.id} />
                  <span style={spanStyle}>{data.data[index]} Uhr</span>
                </div>
              );
            })}
          </div>
        );
      };
      AccordionData.push({
        id: data.id,
        heading: data.heading,
        content: contentContainer,
      });
    });
    return AccordionData;
  };

  const handleDateBlur = (e) => {
    if (e.target.value === "") e.target.type = "text";
  };

  const renderTraining = () => {
    if (training) {
      return (
        <S.TrainingFixedContainer>
          {Data.trainingFixedValues.map((value) => {
            return (
              <S.TrainingFixedCardContainer key={value.id}>
                <Checkbox
                  isChecked={trainingDays.indexOf(value.value) !== -1}
                  onChange={(checked) =>
                    handleTrainingFixedCheckbox(checked, value.value)
                  }
                  id={value.id}
                  name={value.id}
                  value={value.value}
                  margin="7px 28px 0 0"
                />
                <S.TrainingFixedHeading>
                  <TranslationMessage id={value.id} />
                </S.TrainingFixedHeading>
              </S.TrainingFixedCardContainer>
            );
          })}
        </S.TrainingFixedContainer>
      );
    } else {
      return (
        <S.TrainingFlexibleCardContainer>
          <S.TrainingFlexibleCard>
            <S.TrainingFlexibleCardHeading>
              <TranslationMessage id="registration.trainingFlexible.trainingUnitPerWeek" />
            </S.TrainingFlexibleCardHeading>
            <IncrementInput
              width="130"
              placeholder="0"
              value={totalTrainingDays}
              setData={setTotalTrainingDays}
              id="weekDays"
              inputMeasure=""
              maxLimit={7}
            />
          </S.TrainingFlexibleCard>
        </S.TrainingFlexibleCardContainer>
      );
    }
  };

  const nextButtonValue = () => {
    if (id === "personalData") return "part1Register";
    else return "next";
  };

  const onButtonClicked = (index) => {
    console.log("terms value", termsValue);
    if (termsValue && dataProtectionValue) {
      if (index === 0) {
        let newCurrentLinkPosition = Data.RegistrationLinks.indexOf(
          `/registration/${id}`
        );
        let newPrevLink = Data.RegistrationLinks[newCurrentLinkPosition];
        let newNextLink = Data.RegistrationLinks[newCurrentLinkPosition + 1];
        setPrevLink(newPrevLink);
        setNextLink(newNextLink);
        history.push(nextLink);
      }
    } else {
      setShowError(true);
    }
  };

  const appleResponse = (response) => {
    //if (!response.error) {
    //console.log('response', response);
    //   localStorage.setItem('token', res.accessToken);
    //   history.push('/nutrition/creation');
    //}
  };

  const loginButtons = () => (
    <S.DesktopButtonContainer>
      {buttonData.map((button, index) => {
        return (
          <>
            {button.id === "button2" ? (
              // <GoogleLogin
              //   clientId={clientId}
              //   render={(renderProps) => (
              //     <Button
              //       outlined
              //       key={button.id}
              //       color="#fff"
              //       onClick={renderProps.onClick}
              //       disabled={true} //{renderProps.disabled}
              //       id={button.id === "button2" ? "googleLoginButton" : ""}
              //       style={{
              //         backgroundColor: button.color,
              //         padding: "7px 20px",
              //         marginRight: "10px",
              //         height: "43px",
              //         width: 140 + "%",
              //         border: "1px solid",
              //         marginTop: isMobile ? 10 : 0,
              //       }}
              //     >
              //       <S.ButtonInnerContainer>
              //         <S.ButtonImage src={button.image} />
              //         <TranslationMessage id={button.heading} />
              //       </S.ButtonInnerContainer>
              //     </Button>
              //   )}
              //   onSuccess={onSuccess}
              //   onFailure={onFailure}
              //   cookiePolicy={"single_host_origin"}
              //   isSignedIn={true}
              // />
              <> </>
            ) : button.id === "button3" ? (
              <></>
            ) : (
              // <AppleLogin
              //   clientId={appleClientId}
              //   redirectURI="/nutrition/creation"
              //   usePopup={true}
              //   callback={appleResponse} // Catch the response
              //   scope="email name"
              //   responseMode="query"
              //   render={(
              //     renderProps //Custom Apple Sign in Button
              //   ) => (
              //     <Button
              //       outlined
              //       key={button.id}
              //       color="#fff"
              //       onClick={renderProps.onClick}
              //       disabled={true} //{renderProps.disabled}
              //       id={button.id === "button2" ? "googleLoginButton" : ""}
              //       style={{
              //         backgroundColor: button.color,
              //         padding: "7px 20px",
              //         marginRight: "10px",
              //         height: "43px",
              //         width: 140 + "%",
              //         border: "1px solid",
              //         marginTop: isMobile ? 10 : 0,
              //       }}
              //     >
              //       <S.ButtonInnerContainer>
              //         <S.ButtonImage src={button.image} />
              //         <TranslationMessage id={button.heading} />
              //       </S.ButtonInnerContainer>
              //     </Button>
              //   )}
              // />
              <Button
                outlined
                key={button.id}
                color="#fff"
                id={button.id === "button2" ? "googleLoginButton" : ""}
                style={{
                  backgroundColor: button.color,
                  padding: "7px 2px",
                  marginRight: "10px",
                  height: "43px",
                  width: "160px",
                  border: "1px solid",
                  marginTop: isMobile ? -40 : 0,
                }}
                onClick={() => onButtonClicked(index)}
              >
                <S.ButtonInnerContainer>
                  <S.ButtonImage src={button.image} />
                  <TranslationMessage id={button.heading} />
                </S.ButtonInnerContainer>
              </Button>
            )}
          </>
        );
      })}
    </S.DesktopButtonContainer>
  );
  if (
    window.location.pathname == nextLink ||
    window.location.pathname == prevLink
  ) {
    handleLinksPosition(window.location.pathname);
  }
  return (
    <div>
      {!isMobile && (
        <Topbar
          prev={prevLink}
          next={nextLink}
          nextButtonId={nextButtonValue()}
          onNext={updateRegistrationData}
          redirect={handleNextRedirect()}
          arrayData={Data.RegistrationLinks}
          setPrevLink={setPrevLink}
          setNextLink={setNextLink}
          isSocialLogin={
            window.location.pathname == "/registration/socialLogins"
          }
          progress={Data.getProgress(id)}
        />
      )}
      {id === "gender" && (
        <S.RegistrationContainer>
          {isMobile ? (
            <>
              <S.RegistrationMobileHeadingContainer>
                <S.RegistrationMobileHeading>
                  <TranslationMessage id="registration.gender.mobileHeading" />
                </S.RegistrationMobileHeading>
              </S.RegistrationMobileHeadingContainer>
              <S.MobileProgressBarContainer>
                <ProgressBar progress={"14%"} isMobile={true} index={0} />
              </S.MobileProgressBarContainer>
              <S.RegistrationMobileSubHeading>
                <TranslationMessage id="registration.gender.mobileSubHeading" />
              </S.RegistrationMobileSubHeading>
            </>
          ) : (
            <>
              <S.RegistrationHeading>
                <TranslationMessage id="registration.gender.heading" />
              </S.RegistrationHeading>
              <S.RegistrationSubHeading>
                <TranslationMessage id="registration.gender.subHeading" />
              </S.RegistrationSubHeading>
            </>
          )}
          <S.GenderCardContainer>
            <S.GenderCard
              selected={gender === "male"}
              onClick={() => setGender("male")}
            >
              <S.GenderCardImage src={Male} alt="Male" />
              <S.GenderCardHeading>
                <TranslationMessage id="registration.gender.male" />
              </S.GenderCardHeading>
            </S.GenderCard>
            <S.GenderCard
              marginLeft
              selected={gender === "female"}
              onClick={() => setGender("female")}
            >
              <S.GenderCardImage src={Female} alt="Female" />
              <S.GenderCardHeading>
                <TranslationMessage id="registration.gender.female" />
              </S.GenderCardHeading>
            </S.GenderCard>
          </S.GenderCardContainer>
        </S.RegistrationContainer>
      )}
      {id === "goal" && (
        <S.RegistrationContainer>
          {isMobile ? (
            <>
              <S.RegistrationMobileHeadingContainer>
                <S.RegistrationMobileImageContainer>
                  <img
                    onClick={() => handlePrev()}
                    src={LeftIcon}
                    alt="Left Icon"
                  />
                </S.RegistrationMobileImageContainer>
                <S.RegistrationMobileHeading>
                  <TranslationMessage id="registration.goal.mobileHeading" />
                </S.RegistrationMobileHeading>
              </S.RegistrationMobileHeadingContainer>
              <S.MobileProgressBarContainer>
                <ProgressBar progress={"28%"} isMobile={true} index={1} />
              </S.MobileProgressBarContainer>
              <S.RegistrationMobileSubHeading>
                <TranslationMessage id="registration.goal.mobileSubHeading" />
              </S.RegistrationMobileSubHeading>
            </>
          ) : (
            <>
              <S.RegistrationHeading>
                <TranslationMessage id="registration.goal.heading" />
              </S.RegistrationHeading>
              <S.RegistrationSubHeading>
                <TranslationMessage id="registration.goal.subHeading" />
              </S.RegistrationSubHeading>
            </>
          )}
          <S.GoalCardContainer>
            <S.GoalCard selected={goal === 1} onClick={() => setGoal(1)}>
              <S.GoalCardImage src={Slimming} alt="Slimming" />
              <S.GoalCardHeading>
                <TranslationMessage id="registration.goal.slimming" />
              </S.GoalCardHeading>
            </S.GoalCard>
            <S.GoalCard selected={goal === 2} onClick={() => setGoal(2)}>
              <S.GoalCardImage src={HealthDiet} alt="HealthDiet" />
              <S.GoalCardHeading>
                <TranslationMessage id="registration.goal.healthDiet" />
              </S.GoalCardHeading>
            </S.GoalCard>
            <S.GoalCard selected={goal === 3} onClick={() => setGoal(3)}>
              <S.GoalCardImage src={MuscleBuilding} alt="MuscleBuilding" />
              <S.GoalCardHeading>
                <TranslationMessage id="registration.goal.muscleBuilding" />
              </S.GoalCardHeading>
            </S.GoalCard>
            <S.GoalCard selected={goal === 4} onClick={() => setGoal(4)}>
              <S.GoalCardImage
                src={MuscleBuildingBulk}
                alt="MuscleBuildingBulk"
              />
              <S.GoalCardHeading withSubtext>
                <TranslationMessage id="registration.goal.muscleBuildingBulk" />
              </S.GoalCardHeading>
              <S.GoalCardSubHeading>
                <TranslationMessage id="registration.goal.muscleBuildingBulk.subheading" />
              </S.GoalCardSubHeading>
            </S.GoalCard>
          </S.GoalCardContainer>
        </S.RegistrationContainer>
      )}
      {id === "bodyDetails" && (
        <S.RegistrationContainer>
          {isMobile ? (
            <>
              <S.RegistrationMobileHeadingContainer>
                <S.RegistrationMobileImageContainer>
                  <img
                    onClick={() => handlePrev()}
                    src={LeftIcon}
                    alt="Left Icon"
                  />
                </S.RegistrationMobileImageContainer>
                <S.RegistrationMobileHeading>
                  <TranslationMessage id="registration.goal.mobileHeading" />
                </S.RegistrationMobileHeading>
              </S.RegistrationMobileHeadingContainer>
              <S.MobileProgressBarContainer>
                <ProgressBar progress={"38%"} isMobile={true} index={2} />
              </S.MobileProgressBarContainer>
              <S.RegistrationMobileSubHeading>
                <TranslationMessage id="registration.goal.mobileSubHeading" />
              </S.RegistrationMobileSubHeading>
            </>
          ) : (
            <>
              <S.RegistrationHeading>
                <TranslationMessage id="registration.bodyDetails.mobileHeading" />
              </S.RegistrationHeading>
              <S.RegistrationSubHeading>
                <TranslationMessage id="registration.bodyDetails.mobileSubHeading" />
              </S.RegistrationSubHeading>
            </>
          )}
          <S.BodyDetailsCardContainer>
            <S.BodyDetailsCard>
              <S.BodyDetailsCardHeading>
                <TranslationMessage id="registration.bodyDetails.dateOfBirth" />
              </S.BodyDetailsCardHeading>
              <Input
                type="date"
                placeholder="01.01.2000"
                max={moment().add(-1, "days").format("YYYY-MM-DD")}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={handleDateBlur}
                value={birthDate}
                onChange={(e) => setBirthDate(e.target.value)}
                style={{
                  width: "200px",
                  margin: "0 auto",
                  padding: "10px 0px",
                  textAlign: "center",
                }}
              />
              {isFirstTime || birthDate ? (
                ""
              ) : (
                <ErrorMessage error={"Required."} />
              )}
            </S.BodyDetailsCard>
            <S.BodyDetailsCard>
              <S.BodyDetailsCardHeading>
                <TranslationMessage id="registration.bodyDetails.height" />
              </S.BodyDetailsCardHeading>
              <IncrementInput
                width="200"
                placeholder="0 cm"
                id="cm"
                inputMeasure="cm"
                value={height}
                setData={setHeight}
              />
              {isFirstTime ||
                (parseInt(height) <= 120 && (
                  <ErrorMessage
                    error={"Die Mindesthöhe sollte 120 cm betragen."}
                  />
                ))}
            </S.BodyDetailsCard>
            <S.BodyDetailsCard>
              <S.BodyDetailsCardHeading>
                <TranslationMessage id="registration.bodyDetails.weight" />
              </S.BodyDetailsCardHeading>
              <IncrementInput
                width="200"
                placeholder="0 kg"
                id="kg"
                inputMeasure="kg"
                value={weight}
                setData={setWeight}
              />
              {isFirstTime ||
                (parseInt(weight) <= 40 && (
                  <ErrorMessage
                    error={"Das Mindestgewicht sollte 40 kg betragen."}
                  />
                ))}
            </S.BodyDetailsCard>
          </S.BodyDetailsCardContainer>
        </S.RegistrationContainer>
      )}
      {id === "dailyRoutine" && (
        <S.RegistrationContainer>
          {isMobile ? (
            <>
              <S.RegistrationMobileHeadingContainer>
                <S.RegistrationMobileImageContainer>
                  <img
                    onClick={() => handlePrev()}
                    src={LeftIcon}
                    alt="Left Icon"
                  />
                </S.RegistrationMobileImageContainer>
                <S.RegistrationMobileHeading>
                  <TranslationMessage id="registration.dailyRoutine.mobileHeading" />
                </S.RegistrationMobileHeading>
              </S.RegistrationMobileHeadingContainer>
              <S.MobileProgressBarContainer>
                <ProgressBar progress={"50%"} isMobile={true} index={3} />
              </S.MobileProgressBarContainer>
            </>
          ) : (
            <>
              <S.RegistrationHeading>
                <TranslationMessage id="registration.dailyRoutine.heading" />
              </S.RegistrationHeading>
              <S.RegistrationSubHeading>
                <TranslationMessage id="registration.dailyRoutine.subHeading" />
              </S.RegistrationSubHeading>
            </>
          )}
          <S.DailyRoutineCardContainer>
            {!isMobile && <CollapsibleAccordion data={AccordionContent()} />}
            {isMobile && dailyRoutineData.length > 0 ? (
              <CollapsibleAccordion data={AccordionContent()} />
            ) : (
              isMobile && (
                <S.DailyRoutineDescriptionCard>
                  <S.DailyRoutineDescription>
                    <TranslationMessage id="registration.dailyRoutine.description1" />
                  </S.DailyRoutineDescription>
                  <S.DailyRoutineDescription>
                    <TranslationMessage id="registration.dailyRoutine.description2" />
                  </S.DailyRoutineDescription>
                  <S.DailyRoutineDescription>
                    <TranslationMessage id="registration.dailyRoutine.description3" />
                  </S.DailyRoutineDescription>
                </S.DailyRoutineDescriptionCard>
              )
            )}

            <S.AddNewDailyRoutineButtonContainer>
              <Button
                color="#1C9D87"
                outlined
                style={{ width: isMobile ? 85 + "%" : "" }}
                onClick={() => setAddDailyRoutineModal(true)}
              >
                <TranslationMessage
                  id={
                    isMobile
                      ? "button.addDailyRoutine"
                      : "button.addAnotherDailyRoutine"
                  }
                />
              </Button>
            </S.AddNewDailyRoutineButtonContainer>
          </S.DailyRoutineCardContainer>
        </S.RegistrationContainer>
      )}
      {id === "activityLevel" && (
        <S.RegistrationContainer>
          {isMobile ? (
            <>
              <S.RegistrationMobileHeadingContainer>
                <S.RegistrationMobileImageContainer>
                  <img
                    onClick={() => handlePrev()}
                    src={LeftIcon}
                    alt="Left Icon"
                  />
                </S.RegistrationMobileImageContainer>
                <S.RegistrationMobileHeading>
                  <TranslationMessage id="registration.activityLevel.mobileHeading" />
                </S.RegistrationMobileHeading>
              </S.RegistrationMobileHeadingContainer>
              <S.MobileProgressBarContainer>
                <ProgressBar progress={"62%"} isMobile={true} index={4} />
              </S.MobileProgressBarContainer>
              <S.RegistrationMobileSubHeading>
                <TranslationMessage id="registration.activityLevel.subHeading" />
              </S.RegistrationMobileSubHeading>
            </>
          ) : (
            <>
              <S.RegistrationHeading>
                <TranslationMessage id="registration.activityLevel.heading" />
              </S.RegistrationHeading>
              <S.RegistrationSubHeading>
                <TranslationMessage id="registration.activityLevel.subHeading" />
              </S.RegistrationSubHeading>
            </>
          )}
          <S.ActivityLevelContainer>
            {activityLevelValues.map((value) => {
              return (
                <S.ActivityLevelCardContainer key={value.id}>
                  <TranslationMessage id={value.id} />
                  <Dropdown
                    width="80px"
                    options={value.options}
                    margin="none"
                    value={value.value}
                    onChange={(e) =>
                      handleActivityLevelValueChange(e, value.id)
                    }
                    placeholder="0:00"
                  />
                </S.ActivityLevelCardContainer>
              );
            })}
            {isMobile ? (
              <S.ActivityLevelTotalContainer>
                <S.ActivityLevelTotalHeading>
                  <TranslationMessage id="registration.activityLevel.total" />
                </S.ActivityLevelTotalHeading>

                <S.ActivityLevelTotalDescription>
                  <TranslationMessage id="registration.activityLevel.24hLeft.p1" />
                  {24 - totalMomentHrs}
                  <TranslationMessage id="registration.activityLevel.24hLeft.p2" />
                </S.ActivityLevelTotalDescription>
                <S.ActivityLevelTotal>
                  <Input
                    disabled
                    warning
                    value={totalMomentHrs}
                    type="text"
                    placeholder="0 h"
                    style={{ width: "46px" }}
                  />
                </S.ActivityLevelTotal>
              </S.ActivityLevelTotalContainer>
            ) : (
              <S.ActivityLevelTotalContainer>
                <S.ActivityLevelTotalHeading>
                  <TranslationMessage id="registration.activityLevel.total" />
                </S.ActivityLevelTotalHeading>
                <S.ActivityLevelTotal>
                  <Input
                    disabled
                    warning
                    value={totalMomentHrs}
                    type="text"
                    placeholder="0 h"
                    style={{ width: "46px" }}
                  />
                </S.ActivityLevelTotal>
                <S.ActivityLevelTotalDescription>
                  <TranslationMessage id="registration.activityLevel.24hLeft.p1" />
                  {24 - totalMomentHrs}
                  <TranslationMessage id="registration.activityLevel.24hLeft.p2" />
                </S.ActivityLevelTotalDescription>
              </S.ActivityLevelTotalContainer>
            )}
          </S.ActivityLevelContainer>
        </S.RegistrationContainer>
      )}
      {id === "trainingFixed" && (
        <S.RegistrationContainer>
          {isMobile ? (
            <>
              <S.RegistrationMobileHeadingContainer>
                <S.RegistrationMobileImageContainer>
                  <img
                    onClick={() => handlePrev()}
                    src={LeftIcon}
                    alt="Left Icon"
                  />
                </S.RegistrationMobileImageContainer>
                <S.RegistrationMobileHeading>
                  <TranslationMessage id="registration.trainingFixed.mobileHeading" />
                </S.RegistrationMobileHeading>
              </S.RegistrationMobileHeadingContainer>
              <S.MobileProgressBarContainer>
                <ProgressBar progress={"74%"} isMobile={true} index={5} />
              </S.MobileProgressBarContainer>
            </>
          ) : (
            <>
              <S.RegistrationHeading>
                <TranslationMessage id="registration.trainingFixed.heading" />
              </S.RegistrationHeading>
              <S.RegistrationSubHeading>
                <TranslationMessage id="registration.trainingFixed.subHeading" />
              </S.RegistrationSubHeading>
            </>
          )}
          <S.TrainingFixedSwitchContainer>
            <S.TrainingFixedSwitchHeading>
              <TranslationMessage id="registration.trainingFixed.fixedTrainingDays" />
            </S.TrainingFixedSwitchHeading>
            <Switch
              switchedOn={training}
              onChange={() => setTraining(!training)}
            />
          </S.TrainingFixedSwitchContainer>
          {renderTraining()}
        </S.RegistrationContainer>
      )}
      {eatingHabits.map((eatingHabit) => {
        return (
          <>
            {id === eatingHabit.id && (
              <S.RegistrationContainer key={eatingHabit.id}>
                {isMobile ? (
                  <>
                    <S.RegistrationMobileHeadingContainer>
                      <S.RegistrationMobileImageContainer>
                        <img
                          onClick={() => handlePrev()}
                          src={LeftIcon}
                          alt="Left Icon"
                        />
                      </S.RegistrationMobileImageContainer>
                      <S.RegistrationMobileHeading>
                        <TranslationMessage id="registration.eatingHabit.mobileHeading" />
                      </S.RegistrationMobileHeading>
                    </S.RegistrationMobileHeadingContainer>
                    <S.MobileProgressBarContainer>
                      <ProgressBar progress={"86%"} isMobile={true} index={6} />
                    </S.MobileProgressBarContainer>
                    <S.RegistrationMobileSubHeading>
                      <TranslationMessage id="registration.eatingHabit.subHeading" />
                    </S.RegistrationMobileSubHeading>
                  </>
                ) : (
                  <>
                    <S.RegistrationHeading>
                      <TranslationMessage id="registration.eatingHabit.heading" />
                    </S.RegistrationHeading>
                    <S.RegistrationSubHeading>
                      <TranslationMessage id="registration.eatingHabit.subHeading" />
                    </S.RegistrationSubHeading>
                  </>
                )}
                <S.EatingHabitsContainer>
                  {EatingHabitsContent(
                    eatingHabit.data,
                    eatingHabit.id,
                    eatingHabit.state
                  )}
                </S.EatingHabitsContainer>
              </S.RegistrationContainer>
            )}
          </>
        );
      })}
      {isMobile && id !== "socialLogins" && id !== "personalData" && (
        <S.NextButtonContainer>
          <Button
            onClick={() => handleNext()}
            color="#fff"
            style={{
              width: 95 + "%",
              borderRadius: "6px",
              background:
                "linear-gradient(99deg, #2ECF96 0%, #1C9D87 100%) 0% 0% no-repeat padding-box",
            }}
          >
            <TranslationMessage id="button.next" />
          </Button>
        </S.NextButtonContainer>
      )}
      {id === "socialLogins" && (
        <S.RegistrationContainer>
          {isMobile && (
            <>
              <S.RegistrationHeading>
                <TranslationMessage id="registration.personalData.heading" />
              </S.RegistrationHeading>
              <S.RegistrationSubHeading style={{ paddingInline: 40 }}>
                <TranslationMessage id="registration.personalData.subHeading" />
              </S.RegistrationSubHeading>
            </>
          )}
          <S.PersonalDataContainer style={{ height: isMobile ? "" : 450 }}>
            <S.PersonalDataInnerContainer>
              {!isMobile && (
                <>
                  <S.RegistrationHeading>
                    <TranslationMessage id="registration.personalData.heading" />
                  </S.RegistrationHeading>
                  <S.RegistrationSubHeading>
                    <TranslationMessage id="registration.personalData.subHeading" />
                  </S.RegistrationSubHeading>
                </>
              )}
              <S.PersonalDataRulesContainer>
                <S.PersonalDataMobileRowContainer>
                  <C.SquareCheckBoxContainer rounded="7px" filled="#F5F5F5">
                    <C.CustomSquareCheckBox checked={termsValue} />
                    <label onClick={() => setTermsValue(!termsValue)}></label>
                  </C.SquareCheckBoxContainer>
                  <S.PersonalDataRulesLabel
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "registration.personalData.termsAndConditions",
                      }),
                    }}
                  />
                </S.PersonalDataMobileRowContainer>
                <S.PersonalDataMobileRowContainer>
                  <C.SquareCheckBoxContainer rounded="7px" filled="#F5F5F5">
                    <C.CustomSquareCheckBox checked={dataProtectionValue} />
                    <label
                      onClick={() =>
                        setDataProtectionValue(!dataProtectionValue)
                      }
                    ></label>
                  </C.SquareCheckBoxContainer>
                  <S.PersonalDataRulesLabel
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "registration.personalData.DataConsent",
                      }),
                    }}
                  />
                </S.PersonalDataMobileRowContainer>
                {showError && (
                  <S.RegistrationHeading>
                    <TranslationMessage id="registration.personalData.acceptErrorMessage" />
                  </S.RegistrationHeading>
                )}
              </S.PersonalDataRulesContainer>
              <S.RegistrationSubHeading
                style={{
                  fontSize: 16,
                  marginTop: isMobile ? 130 : 50,
                  fontWeight: 600,
                }}
              >
                <TranslationMessage id="registration.login.with" />
              </S.RegistrationSubHeading>
              {loginButtons()}
            </S.PersonalDataInnerContainer>
          </S.PersonalDataContainer>
        </S.RegistrationContainer>
      )}
      {id === "personalData" && (
        <S.RegistrationContainer>
          {isMobile && (
            <S.MobileLogoContainer>
              <S.MobileLogo src={Logo} alt="Logo" />
            </S.MobileLogoContainer>
          )}
          {error == "Benutzer existiert bereits" && <S.Error>{error}</S.Error>}
          <S.PersonalDataContainer>
            {isMobile ? (
              <S.PersonalDataInnerContainer>
                <S.RegistrationMobileHeading style={{ marginTop: 30 }}>
                  <TranslationMessage id="registration.personalData.mobileHeading" />
                </S.RegistrationMobileHeading>
                <S.RegistrationMobileHeading>
                  <S.DividerLine></S.DividerLine>
                </S.RegistrationMobileHeading>
                <S.PersonalDataInputContainer>
                  <Input
                    style={{ width: 100 + "%", marginBottom: "10px" }}
                    placeholder="E-Mail*"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  {/* <Input
                    style={{ width: 100 + "%", marginBottom: "10px" }}
                    type="password"
                    placeholder="Passwort*"
                    onChange={(e) => setPassword(e.target.value)}
                    value={[password]}
                  /> */}
                  <Input
                    style={{ width: 100 + "%", marginBottom: "10px" }}
                    placeholder="Handynummer"
                    onChange={(e) => setMobile(e.target.value)}
                    value={mobile}
                  />
                  <Input
                    style={{ width: 100 + "%", marginBottom: "10px" }}
                    placeholder="Vorname*"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                  />
                  <Input
                    style={{ width: 100 + "%", marginBottom: "10px" }}
                    placeholder="Nachname*"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                  />
                </S.PersonalDataInputContainer>
                {/* <S.PersonalDataRulesContainer isShortTerm={true}>
                                <S.PersonalDataMobileRowContainer>
                                    <SquareCheckbox rounded='7px' filled="#F5F5F5" />
                                    <S.PersonalDataRulesLabel dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "registration.personalData.shortTermsAndConditions" }) }} />
                                </S.PersonalDataMobileRowContainer>
                            </S.PersonalDataRulesContainer> */}
                <Button
                  onClick={() => updateRegistrationData()}
                  color="#fff"
                  style={{
                    width: 95 + "%",
                    borderRadius: "6px",
                    background:
                      "linear-gradient(99deg, #2ECF96 0%, #1C9D87 100%) 0% 0% no-repeat padding-box",
                  }}
                >
                  <TranslationMessage id="button.next.email" />
                </Button>
                <S.PersonalDataCancelButtonContainer>
                  <S.PersonalDataCancelButton onClick={() => handlePrev()}>
                    <TranslationMessage id="registration.personalData.cancel" />
                  </S.PersonalDataCancelButton>
                </S.PersonalDataCancelButtonContainer>
              </S.PersonalDataInnerContainer>
            ) : (
              <S.PersonalDataInnerContainer>
                <S.RegistrationHeading>
                  <TranslationMessage id="registration.personalData.heading" />
                </S.RegistrationHeading>
                <S.RegistrationSubHeading>
                  <TranslationMessage id="registration.personalData.subHeading" />
                </S.RegistrationSubHeading>
                <S.PersonalDataInputContainer>
                  <Input
                    filled
                    style={{ width: "256px", marginBottom: "10px" }}
                    placeholder="Vorname*"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                  />
                  <Input
                    filled
                    style={{ width: "256px", marginBottom: "10px" }}
                    placeholder="Nachname*"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                  />
                  <Input
                    filled
                    style={{ width: "256px", marginBottom: "10px" }}
                    placeholder="E-Mail*"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  <Input
                    filled
                    style={{ width: "256px", marginBottom: "10px" }}
                    placeholder="Handynummer"
                    onChange={(e) => setMobile(e.target.value)}
                    value={mobile}
                  />
                </S.PersonalDataInputContainer>
                {/* <ErrorMessage error={error} /> */}
              </S.PersonalDataInnerContainer>
            )}
          </S.PersonalDataContainer>
        </S.RegistrationContainer>
      )}
      <Modal
        show={addDailyRoutineModal}
        setShow={setAddDailyRoutineModal}
        heading={
          <TranslationMessage id="registration.dailyRoutine.popupHeading" />
        }
      >
        <Input
          type="text"
          id="routineName"
          placeholder="Name des Tagesablaufs"
          value={routineName}
          onChange={(e) => setRoutineName(e.target.value)}
        />
        {Data.AddDailyRoutineValues.map((value) => {
          return (
            <S.DailyRoutineInputContainer key={value.id}>
              <S.DailyRoutineInputLabel>
                <TranslationMessage id={value.id} />
              </S.DailyRoutineInputLabel>
              <TimePicker
                value={new Date(2007, 11, 5, value.hrs, 0, 17)}
                onChange={(time) => {
                  handleTimeChange(time, value.id);
                  setAddDailyRoutineModal(true);
                }}
              />
            </S.DailyRoutineInputContainer>
          );
        })}
        <Button
          color="rgba(46, 207, 150, .2)"
          gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
          margin="30px auto 0px"
          style={{ fontSize: "16px", height: "43px" }}
          onClick={handleAddRoutine}
          disabled={routineName.length === 0}
        >
          <TranslationMessage id="button.addDailyRoutine" />
        </Button>
      </Modal>
    </div>
  );
};

export default Registration;
