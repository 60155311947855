import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import * as F from "./footerNew.style";
import * as G from "styles/General.Styles";

// Images
import Logo from "assets/images/myCoaching/Foodiary-Logo-White.png";
import PlayStore from "assets/images/myCoaching/play-store.png";
import AppStore from "assets/images/myCoaching/app-store.png";
import Instagram from "assets/images/new/instagram.svg";
import Facebook from "assets/images/new/facebook.svg";
import Pinterest from "assets/images/new/pinterest.svg";
import YouTube from "assets/images/new/youtube.svg";
import LinkedIn from "assets/images/new/linkedin.svg";

const Footer = () => {
  const coach = useSelector((state) => state.coach.coach);

  return (
    <F.FooterWrap>
      <G.Container>
        <F.FooterContent>
          <F.FooterContentLogoSection>
            <Link to="/">
              <F.Logo src={Logo} alt="Footer Logo" />
            </Link>
            <F.AppLogo>
              <a href="https://www.foodiary.app/get-the-app/">
                <img src={PlayStore} alt="Android App" />
              </a>
              <a href="https://www.foodiary.app/get-the-app/">
                <img src={AppStore} alt="Ios App" />
              </a>
            </F.AppLogo>
            <F.DescriptionUnderLogos className="ct-text-block mt-8 text-base">
              Smarte Ernährungsplanung und individuelle Betreuung für ein
              gesundes Leben voller Energie und Selbstbewusstsein.
            </F.DescriptionUnderLogos>
          </F.FooterContentLogoSection>
          <F.FooterContentUlSection>
            <F.FooterContentUlSectionInner>
              <F.Title className="text-2xl color-white">Coaching</F.Title>
              <F.FooterLinks>
                <li>
                  <NavLink
                    className="text-base color-paragraph-alt"
                    to="/erstgespraech"
                  >
                    Erstgespräch
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="text-base color-paragraph-alt"
                    to="/coaching-pro"
                  >
                    Coaching PRO
                  </NavLink>
                </li>
                <li>
                  <a
                    href="https://my.foodiary.app"
                    className="text-base color-paragraph-alt"
                  >
                    Login
                  </a>
                </li>
              </F.FooterLinks>
            </F.FooterContentUlSectionInner>

            <F.FooterContentUlSectionInner>
              <F.Title className="text-2xl color-white">Company</F.Title>
              <F.FooterLinks>
                <li>
                  <a
                    className="text-base color-paragraph-alt"
                    href="https://www.foodiary.app/"
                  >
                    Über Foodiary
                  </a>
                </li>
                <li>
                  <a
                    className="text-base color-paragraph-alt"
                    href="/app-erstellen"
                  >
                     App erstellen
                  </a>
                </li>
                <li>
                  <a
                    className="text-base color-paragraph-alt"
                    href="https://mycoach.foodiary.app/"
                  >
                    Coach finden
                  </a>
                </li>
                <li>
                  <a
                    className="text-base color-paragraph-alt"
                    href="https://foodiary.app/partner/coach/"
                  >
                    Werde Coach
                  </a>
                </li>
              </F.FooterLinks>
            </F.FooterContentUlSectionInner>

            <F.FooterContentUlSectionInner>
              <F.Title className="text-2xl color-white">Connect</F.Title>
              <F.FooterLinks>
                <li>
                  <a
                    className="text-base color-paragraph-alt"
                    href={"mailto:" + coach?.email}
                  >
                    {"E-Mail"}
                  </a>
                </li>
                <li>
                  <a
                    className="text-base color-paragraph-alt"
                    href={coach?.instagram_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                </li>
              </F.FooterLinks>
            </F.FooterContentUlSectionInner>
          </F.FooterContentUlSection>
        </F.FooterContent>

        <F.FooterInfo>
          <F.FooterInfoLeft>
            <p className="ct-text-block text-xs">
              © {new Date().getFullYear()} Foodiary GmbH. All rights reserved.
            </p>
          </F.FooterInfoLeft>
          <F.FooterInfoRight>
            <div className="links">
              <a
                href="https://my.foodiary.app"
                target="_blank"
                rel="noreferrer"
              >
                Login für Klienten
              </a>
              <a
                href="https://billing.stripe.com/p/login/8wM28w02eg7fbPa144"
                target="_blank"
                rel="noreferrer"
              >
                Mitgliedschaft kündigen
              </a>
            </div>
            <F.Icons>
              <a href="http://instagram.com/foodiary.app" target="_blank" rel="noreferrer">
                <img src={Instagram} alt="instagram" />
              </a>
              {/* <a href="#" target="_blank" rel="noreferrer">
                <img src={Facebook} alt="facebook" />
              </a> */}
              <a href="https://www.pinterest.de/foodiaryapp/" target="_blank" rel="noreferrer">
                <img src={Pinterest} alt="Pinterest" />
              </a>
              <a href="https://www.linkedin.com/company/34645628/" target="_blank" rel="noreferrer">
                <img src={LinkedIn} alt="linkedin" />
              </a>
            </F.Icons>
          </F.FooterInfoRight>
        </F.FooterInfo>
      </G.Container>
    </F.FooterWrap>
  );
};

export default Footer;
